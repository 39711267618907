import bg from '../assets/bg.png';
import overlaybg from '../assets/overlaybg.png';
import quote from '../assets/quote.png';
import perfume100 from '../assets/perfume100.png'
import heroimg from '../assets/pexels-felipepelaquim-1895015.jpg'
import perfume1 from '../assets/perfume-img1.png'
import prada from '../assets/jeroen-van-roij-sLQt9PjsCcs-unsplash.jpg'
import armani from '../assets/maxim-lozyanko-3dLrus3c_oA-unsplash.jpg'
import last from '../assets/nataliya-melnychuk-49c-5-bNCRk-unsplash.jpg'
import jaguar from '../assets/vishal-banik-OhBmysUAjio-unsplash.jpg'
export default {
  jaguar,
  last,
  armani,
  prada,
  perfume1,
  heroimg,
  bg,
  overlaybg,
  quote,
  perfume100
};
